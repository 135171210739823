import React from 'react'

function Sun(props) {
  return (
    <svg
      className={`ThemeIcon ThemeIcon--sun`}
      role="presentation"
      width={561}
      height={542}
      viewBox="0 0 5610 5420"
      {...props}
    >
      <path d="M2642 4963c-147-276-213-413-207-423 9-15 112-12 294 10 91 11 220 23 285 27 68 5 121 13 123 19 2 5-28 52-67 104s-123 175-187 272c-63 98-121 178-128 178s-58-84-113-187zM4374 4696c-282-111-596-259-616-290-11-17 11-32 97-65 57-22 132-66 255-151l174-120 49-85c27-47 54-85 61-85 19 0 110 117 143 185 59 117 121 330 153 524 31 184 32 212 8 210-7 0-153-56-324-123zM2750 4429c-647-75-1211-529-1410-1138-57-175-74-294-74-511s17-336 74-511c140-426 472-798 885-989 655-302 1434-169 1934 332 246 246 396 524 463 860 32 163 32 453 0 616-67 336-217 614-463 860-362 362-885 541-1409 481zM714 4249c-3-6 34-70 83-143 49-72 138-220 198-328 59-109 113-198 120-198 6 0 36 39 65 88 77 127 147 210 299 359 97 95 133 135 125 143-6 6-140 22-300 35-159 14-351 32-426 40-164 18-155 18-164 4zM4724 3247c-2-7 16-98 40-203l45-190-20-140c-22-157-20-171 13-153 20 11 657 518 698 555 10 10 11 17 3 25s-710 118-761 119c-7 0-15-6-18-13zM879 2785c-170-92-180-96-305-120-134-27-174-39-174-53 0-4 73-67 163-138 123-100 199-151 318-217 85-48 163-87 173-87 9 0 46-7 82-16 44-11 69-13 75-7 7 7-9 71-50 199-58 184-60 193-71 353-10 159-14 181-28 181-4 0-86-43-183-95zM4470 1606l-115-155-110-47c-142-60-162-72-144-89 8-7 207-79 442-160 303-104 432-144 439-137 13 13-363 735-384 740-7 1-65-67-128-152zM1442 1678c-5-7-85-213-177-456-117-310-164-447-157-454 8-8 149 50 469 191 252 112 468 209 480 217 31 19 22 33-50 79-116 75-342 248-444 342-56 51-104 93-106 93-3 0-9-6-15-12z" />
      <path d="M3350 1039c-141-28-152-29-415-26-236 2-270 0-269-13 1-16 568-800 588-812 6-4 16-2 22 5 16 17 266 855 259 867-8 13-17 12-185-21z" />
    </svg>
  )
}

export default Sun
